import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/@next+third-parties@15.2.1_next@15.2.1_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwrigh_hxwh7zwcdmvubp45ih54wfryxi/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/@next+third-parties@15.2.1_next@15.2.1_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwrigh_hxwh7zwcdmvubp45ih54wfryxi/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/@next+third-parties@15.2.1_next@15.2.1_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwrigh_hxwh7zwcdmvubp45ih54wfryxi/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwright+test@1.51.0_react-dom@19._w5mewvn6v2zw5inms6ts7siezi/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwright+test@1.51.0_react-dom@19._w5mewvn6v2zw5inms6ts7siezi/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.3.1_next@15.2.1_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwright+test@1.51.0_r_e63mbzdejiqyjoqzoxwh527uay/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
